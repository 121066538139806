import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { auth } from 'firebase/app';

import { ShinyService } from '../shiny.service';
import { Router } from '@angular/router';

export interface DialogData {
  username: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userData;
  public username = '';
  public error = '';

  constructor(public auth: AngularFireAuth,
    public shinyService: ShinyService,
    public dialog: MatDialog,
    public router: Router) {
    this.shinyService.userInfo.subscribe(data => {
      this.userData = data;
      if (this.userData === undefined || this.userData === []) {
        this.openDialog();
      }
    });
  }

  routeToDex(username: string) {
    this.router.navigate(['dex', username]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UsernameAssignmentDialog, {
      width: '250px',
      data: { username: this.username, error: this.error }
    });

    dialogRef.afterClosed().subscribe(result => {
      try {
        this.shinyService.setUsername(result);
      }
      catch (err) {
        this.error = err;
        this.openDialog();
      }
    });
  }

  ngOnInit(): void {
  }

  login() {
    this.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.shinyService.getUserInfo();

  }
  logout() {
    this.auth.signOut();
  }
}

@Component({
  selector: 'username-assignment-dialog',
  templateUrl: 'username-assignment-dialog.html',
})
export class UsernameAssignmentDialog {

  constructor(
    public dialogRef: MatDialogRef<UsernameAssignmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}