import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, DocumentData } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { User } from 'firebase';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class ShinyService {
  private itemDoc: AngularFirestoreDocument<User>;
  item: Observable<User>;
  userInfo = new Subject<DocumentData>();
  dexInfo = new Subject<DocumentData>();
  villagers = new Subject<any>();
  uid: string;

  profileFound = new Subject<DocumentData>();
  villagerFound = new Subject<DocumentData>();

  constructor(
    public auth: AngularFireAuth,
    public firestore: AngularFirestore
  ) { }

  async setUsername(username: string) {
    this.firestore.doc<User>(`/users/${username}`).get().subscribe(doc => {
      if (doc.data() !== undefined) {
        throw "This username is taken!";
      } else {
        this.firestore.collection('users').doc(username).set({ username: username, uid: this.uid });
        this.getUserInfo();
      }
    });
  }

  getVillagers() {
    this.firestore.collection('villagers').get().subscribe(docs => {
      this.villagers.next(docs.docs.map(doc => doc.data()));
    });
  }

  public setFound(user: any, found: any) {
    this.firestore.doc(`/users/${user.username}`).update({ found: user.found });
    this.firestore.doc(`users/${user.username}/found/${found.name}`).set({ name: found.name, islands: found.islands, date: new Date(), islandtype: found.islandtype, campsitevisitor: found.campsitevisitor, originalresident: found.originalresident });
    this.dexInfo.next(user);
  }

  getUsersDexEntries(username: string) {
    this.firestore.doc(`/users/${username}`).get().subscribe(doc => {
      var dexDoc = doc.data();
      dexDoc.authUser =
        this.dexInfo.next(doc.data());
    });
  }

  getProfileInfo(username: string, villagername: string) {
    this.firestore.doc(`/users/${username}/found/${villagername}`).get().subscribe(foundinfo => {
      this.profileFound.next(foundinfo.data());
    });
    this.firestore.doc(`/villagers/${villagername}`).get().subscribe(vilinfo => {
      this.villagerFound.next(vilinfo.data());
    });
  }


  setSeen(data: any, seen: number) {
    this.firestore.doc(`/users/${data.username}/found/${data.name}`).update({ seen: seen });
  }

  removeEntry(data: any) {
    data.userData.found.splice(data.userData.found.indexOf(data.name), 1);
    this.firestore.doc(`/users/${data.username}`).update({ found: data.userData.found });
    this.firestore.doc(`/users/${data.username}/found/${data.name}`).delete();
    this.userInfo.next(data.userData);
  }

  getUserInfo() {
    this.auth.user.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.firestore.collection(`/users`, ref => ref.where('uid', '==', this.uid)).get().subscribe(docs => {
          this.userInfo.next(docs.docs.map(doc => doc.data())[0]);
        });
      }
    });
  }
}