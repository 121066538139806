<div mat-dialog-content>
        <figure class="villager-pic">
            <img class="villager-pic-pic" src="/assets/images/villagers/{{data.pic}}" alt="{{data.name}}" />
            <figcaption>{{data.picphrase}}</figcaption>
        </figure>
        <div class="info-container">
            <h1>{{data.name}}</h1>
            <span class="islands" *ngIf="data.campsitevisitor || data.originalresident"><img class="tent" src="/assets/images/tent.png" alt="islands" /> {{data.campsitevisitor ? "Campsite Visitor" : "Original Resident"}} </span>
            <span class="islands" *ngIf="!data.campsitevisitor && !data.originalresident"><img src="/assets/images/island.png" alt="islands" /> {{data.islands}} </span>
            <span class="islands"><img class="seen" src="/assets/images/seen.png" alt="seen" (click)="increaseSeen()" />
                {{data.seen ? data.seen : 1}}</span>
            <p>First found by <b>{{data.username}}</b> on {{data.date}}</p>
            <p *ngIf="data.islandtype !== undefined && data.islandtype !== ''"><b>{{data.name}}</b> was found on a <b>{{data.islandtype}}</b></p>
            <p class="species"><b>Species:</b> {{data.species}}</p>
            <p><b>Personality:</b> {{data.personality}}</p>
            <p><b>Birthday:</b> {{data.birthday}}</p>
            <p><b>Catchphrase:</b> {{data.catchphrase}}</p>
        </div>
        <div class="remove"><a *ngIf="data.myDex" (click)="remove()">Remove This Entry</a></div>
</div>