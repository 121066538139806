<section *ngIf="villagerInfo && foundInfo">
    <figure class="villager-pic">
        <img class="villager-pic-pic" src="/assets/images/villagers/{{villagerInfo.pic}}" alt="{{villager}}" />
        <figcaption>{{villagerInfo.picphrase}}</figcaption>
    </figure>
    <div class="info-container">
        <h1>{{villager}}</h1>
        <span class="islands"><img src="/assets/images/island.png" alt="islands" /> {{foundInfo.islands}}   </span>
        <span class="islands"><img class="seen" src="/assets/images/seen.png" alt="seen" /> {{foundInfo.seen ? foundInfo.seen : 1}}</span>
        <p>First found by <b>{{username}}</b> on {{foundDate}}</p>
        <p class="species"><b>Species:</b> {{villagerInfo.species}}</p>
        <p><b>Personality:</b> {{villagerInfo.personality}}</p>
        <p><b>Birthday:</b> {{villagerInfo.birthday}}</p>
        <p><b>Catchphrase:</b> {{villagerInfo.catchphrase}}</p>
    </div>
</section>