import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShinyService } from '../shiny.service';
import { Params, ActivatedRoute, Router } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  name: string;
  islands: number;
}

@Component({
  selector: 'app-shiny-listing',
  templateUrl: './shiny-listing.component.html',
  styleUrls: ['./shiny-listing.component.scss']
})
export class ShinyListingComponent implements OnInit {
  public listings;
  public filteredListings = [];
  public userData;
  public value = '';
  public dexUsername = '';
  public dexFound = [] as any;
  public dexData;
  public myDex = false;
  public foundInfo;
  public foundDate;
  public authUsers = [];

  constructor(public firestore: AngularFirestore,
    public shinyService: ShinyService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.shinyService.profileFound.subscribe(data => {
      this.foundInfo = data;
      this.foundInfo.date = this.foundInfo.date.toDate();
      const villagerInfo = this.listings.filter(a => a.name === this.foundInfo.name)[0];
      const result = Object.assign({}, this.foundInfo, villagerInfo, { username: this.dexUsername, myDex: this.myDex, authUsers: this.authUsers, userData: this.userData });
      this.openFoundDialog(result);
    });
    this.shinyService.villagers.subscribe(data => {
      this.listings = data;
      this.route.params.subscribe(params => {
        this.dexUsername = params.username;
        this.dexFound = [];
        this.shinyService.getUsersDexEntries(params.username);
        this.shinyService.dexInfo.subscribe(dexData => {
          if (dexData) {
            this.dexData = dexData;
            this.setFound(dexData.found);
            if (dexData.authUsers) {
              this.authUsers = dexData.authUsers;
            }
          }
        });
        this.shinyService.userInfo.subscribe(data => {
          this.userData = data;
          if (this.userData && this.userData.username === params.username) {
            this.myDex = true;
            this.setFound(this.userData.found);
          }
        });
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.shinyService.getVillagers();
    this.shinyService.getUserInfo();
  }

  public goToProfile(villager) {
    if (villager.found) {
      this.shinyService.getProfileInfo(this.dexUsername, villager.name);
    } else if ((this.myDex || this.authUsers.includes(this.userData.username))  && !villager.found) {
      this.openDialog(villager.name);
    }
  }

  public setFound(foundData: any[]) {
    if (foundData) {
      if(this.dexFound.length > foundData.length) {
        let difference = this.dexFound.filter(x => !foundData.includes(x));
        this.listings.filter(a => a.name === difference[0])[0].found = false;
      }
      this.dexFound = foundData;

      for (let villager of foundData) {
        this.listings.filter(a => a.name === villager)[0].found = true;
      }
    }
  }

  filterVillagers() {
    this.filteredListings = this.listings.filter(a => a.name.toLowerCase().startsWith(this.value.toLowerCase()));
  }
  openDialog(name: string) {
    const dialogRef = this.dialog.open(FoundDialog, {
      width: '250px',
      data: { name: name, islands: 0, originalresident: false, campsitevisitor: false, islandtype: '', username: this.dexUsername }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.dexData.found) {
        this.dexData.found.push(result.name);
      } else {
        this.dexData.found = [result.name];
      }
      this.shinyService.setFound(this.dexData, result);
    });
  }

  openFoundDialog(villager: any) {
    const dialogRef = this.dialog.open(ProfileDialog, {
      width: '750px',
      data: villager
    });
  }
}

@Component({
  selector: 'found-dialog',
  templateUrl: 'found-dialog.html',
})
export class FoundDialog {

  constructor(
    public dialogRef: MatDialogRef<FoundDialog>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openIslandTypes() {
    var base_url = window.location.origin;
    var win = window.open(`${base_url}/islandtypes`, '_blank');
    win.focus();
  }
}

@Component({
  selector: 'profile-dialog',
  templateUrl: 'profile-dialog.html',
  styleUrls: ['./profile-dialog.scss']
})
export class ProfileDialog {

  constructor(
    public dialogRef: MatDialogRef<ProfileDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shinyService: ShinyService) { }

  increaseSeen() {
    if (this.data.myDex || this.data.authUsers.includes(this.data.userData.username)) {
      this.data.seen = this.data.seen ? this.data.seen + 1 : 2;
      this.shinyService.setSeen(this.data, this.data.seen);
    }
  }

  remove() {
    if (confirm("Are you sure you would like to delete this entry? Deleting this entry will lose all information associated with it.")) {
      this.shinyService.removeEntry(this.data);
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}