<div mat-dialog-content>
  <p>Congratulations! You found {{data.name}}!</p>
  <mat-form-field>
    <mat-label>Island Count</mat-label>
    <input matInput [(ngModel)]="data.islands">
  </mat-form-field>
  <mat-form-field class="islandtype">
    <mat-label>Island Type</mat-label>
    <mat-select [(value)]="data.islandtype">
      <mat-option value="">
        Not Sure
      </mat-option>
      <mat-option value="Spiral River Island">
        Spiral River Island
      </mat-option>
      <mat-option value="Fidget Spinner Island">
        Fidget Spinner Island
      </mat-option>
      <mat-option value="Short River Island">
        Short River Island
      </mat-option>
      <mat-option value="Short River and Pond Island">
        Short River and Pond Island
      </mat-option>
      <mat-option value="Tarantula Island">
        Tarantula Island
      </mat-option>
      <mat-option value="Bamboo Island">
        Bamboo Island
      </mat-option>
      <mat-option value="Money Rock Island">
        Money Rock Island
      </mat-option>
      <mat-option value="Scorpion and Money Rock Island">
        Scorpion and Money Rock Island
      </mat-option>
      <mat-option value="Trash Island">
        Trash Island
      </mat-option>
      <mat-option value="Finned Fish Island">
        Finned Fish Island
      </mat-option>
      <mat-option value="Waterfall Island">
        Waterfall Island
      </mat-option>
      <mat-option value="Mountain Island">
        Mountain Island
      </mat-option>
      <mat-option value="Sister Fruit Island">
        Sister Fruit Island
      </mat-option>
      <mat-option value="Big Fish Island">
        Big Fish Island
      </mat-option>
      <mat-option value="Gold Rock and Scorpion Island">
        Gold Rock and Scorpion Island
      </mat-option>
      <mat-option value="Rugged Tree Island">
        Rugged Tree Island
      </mat-option>
      <mat-option value="Flat Tree Island">
        Flat Tree Island
      </mat-option>
      <mat-option value="Curly River Island">
        Curly River Island
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-stroked-button (click)="openIslandTypes()">Island Types Info</button>
  <mat-checkbox [(ngModel)]="data.originalresident">Original Resident</mat-checkbox>
  <mat-checkbox [(ngModel)]="data.campsitevisitor">Campsite Visitor</mat-checkbox>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>