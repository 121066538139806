<div mat-dialog-content>
  <p>What Shall We Call You?</p>
  <mat-form-field>
    <mat-label>Username</mat-label>
    <input matInput [(ngModel)]="data.username">
    <p class="err">{{data.error}}</p>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.username" cdkFocusInitial>Ok</button>
</div>
