import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-island-types',
  templateUrl: './island-types.component.html',
  styleUrls: ['./island-types.component.scss']
})
export class IslandTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
