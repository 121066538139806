<section>
    <div class="listing">
        <h4>Spiral River Island    9.68%</h4>
        <p>An island with a river that winds in a spiral. It is part of the subset of four "starter" islands that players will randomly arrive at for their very first mystery tour.</p>
        <img src="/assets/images/islands/spiralriver.png" alt="Spiral River"/>
    </div>
    <div class="listing">
        <h4>Fidget Spinner Island    9.68%</h4>
        <p>An island with a large pond in the middle and a small 2nd level to the northwest. The pond's shape has been compared to a fidget spinner, and it has abundant pond fish such as koi. It is part of the subset of four "starter" islands that players will randomly arrive at for their very first mystery tour.</p>
        <img src="/assets/images/islands/fidgetspinner.png" alt="Fidget Spinner"/>
    </div>
    <div class="listing">
        <h4>Short River Island    9.68%</h4>
        <p>An island two layers, with a river that begins on a small second layer in the north, flows down a waterfall and turns to the east. It is part of the subset of four "starter" islands that players will randomly arrive at for their very first mystery tour.</p>
        <img src="/assets/images/islands/shortriver.png" alt="Short River"/>
    </div>
    <div class="listing">
        <h4>Short River and Pond Island    9.68%</h4>
        <p>It has almost the exact same layout as short river island #1, but with the addition of a pond in the southwest. It is part of the subset of four "starter" islands that players will randomly arrive at for their very first mystery tour.</p>
        <img src="/assets/images/islands/shortriverandpond.png" alt="Short River and Pond"/>
    </div>
    <div class="listing">
        <h4>Tarantula Island    2% (rare)</h4>
        <p>A one-layer island where the only bugs that spawn are tarantulas depending on the time of year. It has an octagonal stream that surrounds a smaller island in the center. The stream is narrow enough that it can be jumped across without a vaulting pole. This layout can only appear between 7pm and 4am, the time when both insects are active.</p>
        <img src="/assets/images/islands/tarantula.png" alt="Tarantula"/>
    </div>
    <div class="listing">
        <h4>Bamboo Island    10%</h4>
        <p>A one-layer island where bamboo is found. While there are palm trees on the beaches, there are no fruit, hardwood, or cedar trees. This island has the highest encounter rate and is statistically the most common layout that will be encountered.</p>
        <img src="/assets/images/islands/bamboo.png" alt="Bamboo"/>
    </div>
    <div class="listing">
        <h4>Money Rock Island    5%</h4>
        <p>Requires the Resident Services building upgrade. An island of nothing but money rocks surrounded by water. By eating fruit, the player can break a rock to the north which will open up space for the player to vault to the central island. The rocks in the central island are all money rocks and will drop a total of 80,500 Bells. If you break the rock at the north to get to the center island before hitting it once, you get an additional 8,000 Bells, totaling 88,500 for the whole island.</p>
        <img src="/assets/images/islands/moneyrock.png" alt="Money Rock"/>
    </div>
    <div class="listing">
        <h4>Scorpion and Money Rock Island    1% (rare)</h4>
        <p>Requires the Resident Services building upgrade. This island has four small triangular cliffs in the northeast, southeast, southwest, and northwest corners, and no water features. The ground between the cliffs is scattered with money rocks and flowers. This island also has an abundance of scorpions, depending on the time of year.</p>
        <img src="/assets/images/islands/scorpionandmoneyrock.png" alt="Scorpion and Money Rock"/>
    </div>
    <div class="listing">
        <h4>Trash Island    1%</h4>
        <p>An island where seemingly the only "fish" that spawn in the river are trash items. This island is good for farming Nook Miles and DIY recipes that use trash, as well as any bugs that spawn on water. It also has an increased dragonfly rate.</p>
        <img src="/assets/images/islands/trash.png" alt="Trash"/>
    </div>
    <div class="listing">
        <h4>Finned Fish Island    0.5% (rare)</h4>
        <p>Requires the Resident Services building upgrade. An island with a rectangular pond with rectangular cliffs inside, the tallest one being too tall to climb up to. The only fish that spawn here are the fish that have fins sticking out of the water such as sharks, ocean sunfish, and suckerfish. This island can only appear during times of the year where finned fish spawn, and no fish will ever spawn in the river, not even if fish bait is used.</p>
        <img src="/assets/images/islands/finnedfish.png" alt="Finned Fish"/>
    </div>
    <div class="listing">
        <h4>Waterfall Island    10%</h4>
        <p>A three-layer island dominated by cliffs, rivers, and waterfalls. The river flows in a counter-clockwise path, from the third layer down into a small open area of water, which splits into three other sections, with the right-most section leading into a river-mouth. The other two sections lead to a small basin. This island is useful for getting cliff-top fish like the stringfish and char, and since it has also a single mouth for the river, it also makes river-mouth spawning fish like sturgeon easier to farm.</p>
        <img src="/assets/images/islands/waterfall.png" alt="Waterfall"/>
    </div>
    <div class="listing">
        <h4>Mountain Island    8%</h4>
        <p>A three-tiered island, the first tier contains hardwood or cedar trees and the second tier contains only fruit trees. The top tier has many flowers and five rocks. There are no ponds or rivers, so only ocean fish can be caught. Previously, the only bugs that spawned here were those that appear when hitting rocks, such as centipedes and pill bugs, but now all bugs spawn as expected. If you clear out all of the island, as you would do when making a tarantula island, you can farm tiger beetles and other ground-spawning bugs.</p>
        <img src="/assets/images/islands/mountain.png" alt="Mountain"/>
    </div>
    <div class="listing">
        <h4>Sister Fruit Island    10%</h4>
        <p>An island with 19 fruit trees that are of a different type than your native fruit. The fruit here will always be the same ones referred to as "sister fruit". The island has a short river with an outlet on the west coast.</p>
        <img src="/assets/images/islands/sisterfruit.png" alt="Sister Fruit"/>
    </div>
    <div class="listing">
        <h4>Big Fish Island    3% (rare)</h4>
        <p>An island where the only fish that spawn are size 4 or larger, making it a great place to catch rare size 6 fish. The river originates on a small plateau in the northeastern corner of the island, flows down waterfalls on the southern and western edges of the plateau, and rejoins to flow through a single mouth at the western edge, forming a small river island in the center. The island has four rocks, four palm trees, four trees of the player's native fruit and six other deciduous trees.</p>
        <img src="/assets/images/islands/bigfish.png" alt="Big Fish"/>
    </div>
    <div class="listing">
        <h4>Gold Rock and Scorpion Island    0.3% (rare)</h4>
        <p>Requires the Resident Services building upgrade. A rare island with a single rock in the middle which only drops gold nuggets when hit. The rock is surrounded by a rectangular pond with a cliff to the south. The ladder and vaulting pole is required to access the rock. The only insects that spawn are scorpions during the season they are available</p>
        <img src="/assets/images/islands/goldrockandscorpion.png" alt="Gold Rock and Scorpion"/>
    </div>
    <div class="listing">
        <h4>Rugged Tree Island    1.5% (rare)</h4>
        <p>Requires the Resident Services building upgrade. An island with no water features and no fruit trees other than coconut trees. It has three small cliff areas, two with a single level and one with two levels. The hardwood trees are plentiful and densely-packed. The only insects that spawn are those associated with trees such as the Goliath beetle, Rainbow stag, and Atlas moth.</p>
        <img src="/assets/images/islands/ruggedtree.png" alt="Rugged Tree"/>
    </div>
    <div class="listing">
        <h4>Flat Tree Island    1% (rare)</h4>
        <p>This island has the exact same layout as Bamboo Island, but there are only hardwood trees on it instead of bamboo. The only insects that spawn are those associated with trees such as the Walking stick, Earth-boring dung beetle, and Horned atlas.</p>
        <img src="/assets/images/islands/flattree.png" alt="Flat Tree"/>
    </div>
    <div class="listing">
        <h4>Curly River Island    8%</h4>
        <p>An island with a single, square-shaped cliff in the northeast with a waterfall that feeds a river that flows in a counter-clockwise loop around the island to an outlet on the east coast. Only dragonflies and insects associated with water spawn here.</p>
        <img src="/assets/images/islands/curlyriver.png" alt="Curly River"/>
    </div>
</section>
<section class="attribution">Information provided by Miho & Nyxis.  Island maps provided by Nyxis.</section>
