<div class="progress-search">
    <mat-progress-bar mode="determinate" value="{{dexFound ? dexFound.length/391*100 : 0}} "></mat-progress-bar>
    <span class="progress-text">{{dexUsername}} has found {{dexFound ? dexFound.length : 0}} of 391 Villagers</span>
    <mat-form-field class='search-input'>
        <input matInput type="text" [(ngModel)]="value" (keyup)="filterVillagers()" placeholder="Search by Villager Name">
        <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>
<section class="polaroid-container" *ngFor="let listing of value !== '' ? filteredListings : listings">
    <div class="polaroid" [attr.polaroid-caption]="listing.name" [ngClass]="{'found' : listing.found}" (click)="goToProfile(listing)">
        <img src="/assets/images/villagers/{{listing.pic}}" alt="{{listing.name}}" />
    </div>
</section>