<div class="login">
    <mat-icon aria-hidden="false" aria-label="Go Home" routerLink="">home</mat-icon>
    <div *ngIf="auth.user | async as user; else showLogin">
        <span>Hello, {{userData && userData.username ? userData.username : "friend"}} </span>
        <button mat-button *ngIf="userData && userData.username"(click)="routeToDex(userData.username)">My Dex</button>
        <button mat-button (click)="logout()">Logout</button>
    </div>
    <ng-template #showLogin>
        <button mat-button (click)="login()">Log In</button>
    </ng-template>
</div>
<header>
    <h1>Animal Crossing: New Horizons ShinyDex</h1>
</header>