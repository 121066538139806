import { Component, OnInit } from '@angular/core';

import { Params, ActivatedRoute, Router } from '@angular/router';
import { ShinyService } from '../shiny.service';



@Component({
  selector: 'app-shiny-profile',
  templateUrl: './shiny-profile.component.html',
  styleUrls: ['./shiny-profile.component.scss']
})
export class ShinyProfileComponent implements OnInit {
  public username: string;
  public villager: string;
  public villagerInfo;
  public foundInfo;
  public foundDate;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private shinyService: ShinyService
    ) { 
      this.shinyService.profileFound.subscribe(data => {
        this.foundInfo = data;
        this.foundDate = this.foundInfo.date.toDate();
      });
      this.shinyService.villagerFound.subscribe(data => {
        this.villagerInfo = data;
      });
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.username = params.username;
      this.villager = params.villager;
      this.getInfo();
    });

  }

  getInfo() {
    this.shinyService.getProfileInfo(this.username, this.villager);
  }

}
