import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShinyListingComponent } from './shiny-listing/shiny-listing.component';
import { ShinyProfileComponent } from './shiny-profile/shiny-profile.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IslandTypesComponent } from './island-types/island-types.component';


const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'dex/:username', component: ShinyListingComponent},
  { path: 'dex/', component: ShinyListingComponent},
  { path: 'dex/:username/:villager', component: ShinyProfileComponent},
  { path: 'islandtypes', component: IslandTypesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
